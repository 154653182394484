import React, { useState }from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  CardHeader,
  TextField,
  Grid,
  FilledInput,
  InputLabel,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  useScrollTrigger,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';

// import { store } from './Store.js';
import './App.css';
// import Search from './Search';
import PlayerFocus from './PlayerFocus.js';


const styles = theme => ({
  root: {
    margin: 4,
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    paddingLeft: 50,
  },
  inputCard: {
    display: "flex",
  },
  listSection: {
    flex: 1,
    marginTop: 24,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 345,
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
});


const App = (props) => {
  // const globalState = useContext(store);
  // const { state } = globalState;
  // state.teamData.length ? console.log('teamData: ', state.teamData[0].season) : console.log('waiting')

  const [checked, setChecked] = useState([0]);
  const [searchValue, setSearchValue] = useState('Search League Rosters');
  const [players] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <div className={props.classes.root}>
      <CardHeader
        title="Player Search"
        subheader="Search for and Download Player headshots and Data"
      />
      <div className={props.classes.appBarOffset} />
        <main>
          <div className={props.classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4} lg={3}>
                {/* <TextField
                  className={props.classes.inputCard}
                  error
                  id="filled-error-helper-text"
                  label="Search League Rosters"
                  defaultValue=""
                  // helperText="e.g. 'Tom', 'Brady', 'Tom Brady'"
                  variant="filled"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>,
                  }}
                /> */}
                {/* <InputLabel htmlFor="filled-adornment-password">Password</InputLabel> */}
                <FilledInput
                  className={props.classes.inputCard}
                  id="filled-adornment-password"
                  type='text'
                  error
                  value={searchValue}
                  onChange={handleSearchChange}
                  // onClick={() => setSearchValue('')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        // onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <List className={props.classes.listSection}>
                  {players.map((value) => {
                    const labelId = `checkbox-list-label-${value}`;

                    return (
                      <ListItem key={value} role={undefined} dense button onClick={handleToggle(value)}>
                        <ListItemIcon>
                          <Radio
                            color={"primary"}
                            edge="start"
                            checked={checked.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`Line item ${value + 1}`} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="comments">
                            <GetAppIcon color={"primary"}/>
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <PlayerFocus />
              </Grid>
            </Grid>
          </div>
        </main>
    </div>
  );
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(App);