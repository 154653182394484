import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
    Card,
    Typography,
    Paper,
    Avatar,
    CircularProgress
} from '@material-ui/core';
import { lightBlue, grey, orange } from '@material-ui/core/colors';

import { store } from './Store.js';
import './App.css';

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    cardTitle: {
        display: 'flex',
        flexDirection: 'row',
        fontWeight: "bold",
        color: lightBlue[500],
        justifyContent: 'left',
        whiteSpace: 'nowrap',
        // borderWidth: 1,
        // borderColor: '#FFFFFF',
        // borderStyle: 'solid'
    },
    cardInfo: {
        display: 'flex',
        flexDirection: 'row'
    },
    statTitle: {
        fontWeight: 'bold',
        color: orange[300]
    },
    stat: {
        marginLeft: 10,
        fontWeight: 'bold',
        color: orange[700]
    },
    cardRoot: {
        // marginLeft: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
    },
    playerCard: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
    },
    paper: {
        display: 'flex',
        margin: theme.spacing(1),
        padding: 2,
        backgroundColor: '#303030',
    },
    bigAvatar: {
        width: 138,
        height: 138,
    },
    progress: {
        margin: 44,
    },
    backgroundLogo: {
        // backgroundSize: "100px 100px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "-40px 0px"
    },
    playerDetails: {
        flex: "1 0 auto",
        paddingLeft: theme.spacing(2),
        flexWrap: 'nowrap',
    },
    name: {
        marginTop: 10,
        fontWeight: "bold",
        fontSize: 15,
        color: grey[400],
        whiteSpace: 'nowrap',
    },
    body: {
        color: grey[500],
        fontSize: 12
    },
});


const MostFrequent = (props) => {
    const globalState = useContext(store);
    const { state } = globalState;

    const setHeadshot = (headshotURL) => {
        return headshotURL.replace('{formatInstructions}', state.headshotFormatInstructions);
    }

    const setTeamLogo = (team) => {
        let dataArray = state.teamData;

        for (let val in dataArray) {
            if (dataArray[val].abbr === state.playerMostFreq.team) {
                let teamLogoURL = dataArray[val].logo;

                return teamLogoURL.replace('{formatInstructions}', state.teamLogoFormatInstructions);
            }
        }
    }

    let headshotNode;

    if (state.players.length) {
        headshotNode =
            <div
                className={props.classes.backgroundLogo}
                style={{
                    backgroundImage: `url(${state.playerMostFreq.team !== '' ? setTeamLogo(state.playerMostFreq.team) : null})`
                }}>
                <Avatar
                    variant="square"
                    src={state.playerMostFreq.headshot ? setHeadshot(state.playerMostFreq.headshot) : null}
                    className={props.classes.bigAvatar}
                />
            </div>
    }
    else {
        headshotNode =
            <CircularProgress
                className={props.classes.progress}
                color='secondary'
                size={50}
                thickness={5}
            />
    }

    return (
        <div className={props.classes.root}>
            <Card variant="outlined" square>
                <div className={props.classes.playerCard}>
                    <Paper
                        variant="outlined" square
                        className={props.classes.paper}
                    >
                        {headshotNode}
                    </Paper>
                    <div className={props.classes.cardRoot}>
                        <div className={props.classes.cardRoot}>
                            <Typography className={props.classes.cardTitle} variant="body2">
                                MOST FREQUENT
                                </Typography>
                        </div>
                        <div className={props.classes.cardInfo}>
                            <Typography className={props.classes.statTitle} variant="body2">
                                First Name:
                            </Typography>
                            <Typography className={props.classes.stat} variant="body2">
                                {state.players.length ? state.mostFreqFirstName : 0}
                            </Typography>
                        </div>
                        <div className={props.classes.cardInfo}>
                            <Typography className={props.classes.statTitle} variant="body2">
                                Last Name:
                            </Typography>
                            <Typography className={props.classes.stat} variant="body2">
                                {state.players.length ? state.mostFreqLastName : 0}
                            </Typography>
                        </div>
                        <Typography className={props.classes.name} component="h6" variant="h6">
                            {state.playerMostFreq.firstName.length ? state.playerMostFreq.firstName + ' ' + state.playerMostFreq.lastName : "Player Name"}
                        </Typography>
                        <Typography className={props.classes.body} variant="body1" color="textSecondary">
                            Position: {state.playerMostFreq.position ? state.playerMostFreq.position : "Pos"}
                        </Typography>
                        <Typography className={props.classes.body} variant="body1" color="textSecondary">
                            Number: {state.playerMostFreq.jerseyNumber}
                        </Typography>
                        <Typography className={props.classes.body} variant="body1" color="textSecondary">
                            Team: {state.playerMostFreq.team ? state.playerMostFreq.team : "Tricode"}
                        </Typography>
                        {/* <div className={props.classes.teamDetails}>
                            <Typography className={props.classes.body} variant="body2" color="textSecondary">
                                {state.playerMostFreq.team.length ? state.playerMostFreq.team : "Team Tricode"}
                            </Typography>
                        </div> */}
                    </div>
                </div>
            </Card>
        </div>
    );
}

MostFrequent.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MostFrequent);
