import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { lightBlue, orange, grey } from '@material-ui/core/colors';

import { StateProvider } from './Store.js';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: lightBlue[700],
        },
        secondary: {
            main: grey[600],
        },
        textColor: {
            light: grey[300],
            medium: grey[600],
            dark: grey[900],
        },
        error: {
            main: orange.A700,
        },
        type: 'dark',
    },
    typography: {
        useNextVariants: true,
    },
});

console.log(theme);

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <StateProvider>
            <App />
        </StateProvider>
    </MuiThemeProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
