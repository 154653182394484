import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Card,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { grey, lightBlue, amber } from '@material-ui/core/colors';

import { Bar } from "react-chartjs-2";

import { store } from './Store.js';
import './App.css';

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  title: {
    paddingLeft: theme.spacing(2)
  },
  cardRoot: {
    display: "flex",
    flexDirection: "column"
  },
  chart: {
    padding: theme.spacing(1),
    paddingTop: 0,
    height: 300,
  },
  progress: {
    margin: 100,
    alignSelf: 'center',
  },
});


const FrequenciesFirst = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;

  const [chartData, setChartData] = useState({});

  useEffect(() => {
    setChartData({
      labels: state.allFreqsFirst.labels,
      datasets: [
        {
          label: '90% League Coverage',
          data: state.allFreqsFirst.data,
          backgroundColor: state.allFreqsFirst.colors,
          barPercentage: 0.95,
          categoryPercentage: 0.95,
          minBarLength: 4,
          borderWidth: 0
        }
      ]
    })
  }, [state.allFreqsFirst.labels, state.allFreqsFirst.data, state.allFreqsFirst.colors]);

  let chartNode;

  if (state.allFreqsFirst.labels.length && state.allFreqsFirst.data.length && state.allFreqsFirst.colors.length) {
    chartNode =
      <div className={props.classes.chart}>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'top',
              labels: {
                fontColor: grey[500],
                fontStyle: 'normal'
              }
            },
            title: {
              display: true,
              padding: 10,
              text: 'FIRST NAME FREQUENCIES',
              fontColor: lightBlue[500],
              fontSize: 15
            },
            tooltips: {
              enabled: true,
              titleFontSize: 16,
              titleFontColor: '#000000',
              bodyFontColor: '#000000',
              footerFontSize: 11,
              footerFontColor: '#000000',
              footerFontStyle: 'bold',
              backgroundColor: amber[400],
              displayColors: false,
              callbacks: {
                title: function (tooltipItem, data) {
                  // console.log(tooltipItem[0].index)
                  return tooltipItem[0].yLabel + ' Players';
                },
                label: function (tooltipItem, data) {
                  let currentIndex = tooltipItem.index;
                  let countArray = data.datasets[0].data;
                  let sum = 0;

                  for (let i = 0; i < currentIndex + 1; i++) {
                    sum += countArray[i];
                  }
                  // console.log(data.datasets[0].data);
                  return sum + ' Cumulative players';
                },
                footer: function (tooltipItem, data) {
                  let currentIndex = tooltipItem[0].index;
                  let countArray = data.datasets[0].data;
                  let totPlyrs = countArray.reduce(function myFunc(total, num) {
                    return total + num;
                  });

                  let sum = 0;

                  for (let i = 0; i < currentIndex + 1; i++) {
                    sum += countArray[i];
                  }
                  // console.log(tooltipItem[0].index);
                  return Number((sum / totPlyrs) * 100).toFixed(2) + '% League Coverage';
                }
              }
            },
            scales: {
              xAxes: [{
                ticks: {
                  fontColor: '#C0C0C0',
                  fontSize: 16,
                  fontStyle: 'bold'
                },
                gridLines: {
                  display: false
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Number of Characters',
                  fontColor: grey[600]
                }
              }],
              yAxes: [{
                ticks: {
                  fontColor: '#C0C0C0',
                  fontSize: 10
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Number of Players',
                  fontColor: grey[600]
                }
              }]
            }
          }}
        />
      </div>
  }
  else {
    chartNode =
      <CircularProgress
        className={props.classes.progress}
        color='secondary'
        size={100}
        thickness={5}
      />
  }

  return (
    <main>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card variant="outlined" square className={props.classes.cardRoot}>
            {chartNode}
          </Card>
        </Grid>
      </Grid>
    </main>
  );
}

FrequenciesFirst.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(FrequenciesFirst);
