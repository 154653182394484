import React, { createContext, useReducer, useEffect } from 'react';
import { grey, orange } from '@material-ui/core/colors';

const crypto = require('crypto');
const axios = require('axios');

console.log(process.env.REACT_APP_NGS_USERNAME)

const store = createContext();
const { Provider } = store;

const initialState = {
    players: [],
    teamData: [],
    charCountsFirstName: [],
    charCountsLastName: [],
    avgFirstName: 0,
    avgLastName: 0,
    mostFreqFirstName: 0,
    mostFreqLastName: 0,
    allFreqsFirst: {
        labels: [],
        data: [],
        colors: [],
    },
    allFreqsLast: {
        labels: [],
        data: [],
        colors: [],
    },
    mostCoverageFirst: 0,
    mostCoverageLast: 0,
    playerAvg: {
        firstName: '',
        lastName: '',
        id: '',
        pos: '',
        jerseyNumber: 0,
        team: '',
        headshot: '',
    },
    playerMostFreq: {
        firstName: '',
        lastName: '',
        id: '',
        pos: '',
        jerseyNumber: 0,
        team: '',
    },
    playerMostCoverage: {
        firstName: '',
        lastName: '',
        id: '',
        pos: '',
        jerseyNumber: 0,
        team: '',
    },
    // FORMATTING DOCUMENTATION: https://cloudinary.com/documentation/image_transformations
    headshotFormatInstructions: 'h_350,f_auto,q_auto,c_fill,g_auto',  // w_420,h_420,f_auto,q_auto,ar_1:1,c_fill,g_auto
    teamLogoFormatInstructions: 'w_100,f_auto,q_auto,c_fill,g_auto'
}

const sum = values => values.reduce((r, v) => r + v, 0);
const avg = values => sum(values) / values.length;

const mostFreq = (values) => {
    return values.reduce(
        (a, b, i, arr) => (arr.filter(v => v === a).length >= arr.filter(v => v === b).length ? a : b),
        null
    )
};

const getMostCoverage = (obj) => {
    let data = obj.data;
    let sum = 0;
    let totPlyrs = data.reduce((total, num) => {
        return total + num;
    });
    let lastIndex = 0;

    for (let i = 0; i < data.length; i++) {
        sum += data[i];
        let percentage = Number((sum / totPlyrs) * 100).toFixed(2);

        if (percentage < 90) {
            lastIndex = i;
        }
        else if (i === lastIndex + 1) {
            return obj.labels[lastIndex + 1]
        }
    }
}

const getAllFrequencies = (arr) => {
    function getFreqs(arr) {
        let a = [];
        let b = [];
        let prev;

        // arr.sort();
        arr.sort(function (a, b) {
            return (+a) - (+b);
        });

        for (let item in arr) {
            if (arr[item] !== prev) {
                a.push(arr[item]);
                b.push(1);
            } else {
                b[b.length - 1]++;
            }
            prev = arr[item];
        }

        return [a, b];
    }

    return getFreqs(arr);

};

const getColors = (data) => {
    let colors = [];
    let sum = 0;
    let totPlyrs = data.reduce(function myFunc(total, num) {
        return total + num;
    });
    let lastIndex = 0;

    for (let i = 0; i < data.length; i++) {
        sum += data[i];
        let percentage = Number((sum / totPlyrs) * 100).toFixed(2);

        if (percentage < 90) {
            colors.push(orange[700]);  // ALL BARS BELOW 90%
            lastIndex = i;
        }
        else {
            i === lastIndex + 1 ? colors.push(orange[700]) : colors.push(grey[900]);  // ADJUST FOR FIRST BAR TO EQUAL 90%
        }
    }
    return colors;
}

const getSampleName = (players, firstCount, lastCount) => {
    let samplePlayers = [];

    for (let player in players) {
        let samplePlayer = {};

        ///// CONSOLE LOG LONGEST PLAYER NAMES FOR DATA CHECK
        // if (players[player].footballName.length > 11) {
        //     console.log(players[player].footballName, players[player].teamAbbr);
        // }

        // if (players[player].lastName.length > 15) {
        //     console.log(players[player].lastName, players[player].teamAbbr);
        // }

        if (players[player].firstName.length === firstCount && players[player].lastName.length === lastCount) {
            samplePlayer.firstName = players[player].firstName;
            samplePlayer.lastName = players[player].lastName;
            samplePlayer.id = players[player].esbId;
            samplePlayer.position = players[player].position;
            samplePlayer.jerseyNumber = players[player].jerseyNumber;
            samplePlayer.team = players[player].teamAbbr;
            samplePlayer.headshot = players[player].headshot;

            samplePlayers.push(samplePlayer);
        }
    }
    return samplePlayers;
}


const reducer = (state, action) => {
    let result;
    let numPlayers;
    let x;

    switch (action.type) {
        case 'SET_PLAYERS':
            return { ...state, players: action.value };
        case 'SET_TEAM_LOGOS':
            return { ...state, teamData: action.value };
        case 'SET_COUNTS_FIRSTNAME':
            return { ...state, charCountsFirstName: state.players.map(p => p.footballName.length) };
        case 'SET_COUNTS_LASTNAME':
            return { ...state, charCountsLastName: state.players.map(p => p.lastName.length) };
        case 'SET_AVG_FIRSTNAME':
            return { ...state, avgFirstName: Math.round(avg(state.charCountsFirstName)) };
        case 'SET_AVG_LASTNAME':
            return { ...state, avgLastName: Math.round(avg(state.charCountsLastName)) };
        case 'SET_MOST_FREQUENT_FIRSTNAME':
            return { ...state, mostFreqFirstName: Math.round(mostFreq(state.charCountsFirstName)) };
        case 'SET_MOST_FREQUENT_LASTNAME':
            return { ...state, mostFreqLastName: Math.round(mostFreq(state.charCountsLastName)) };
        case 'SET_ALL_FREQS_FIRSTNAME':
            result = getAllFrequencies(state.charCountsFirstName);
            numPlayers = result[1];
            return {
                ...state,
                allFreqsFirst: {
                    labels: result[0],
                    data: result[1],
                    colors: getColors(numPlayers)
                }
            };
        case 'SET_ALL_FREQS_LASTNAME':
            result = getAllFrequencies(state.charCountsLastName);
            numPlayers = result[1];

            return {
                ...state,
                allFreqsLast: {
                    labels: result[0],
                    data: result[1],
                    colors: getColors(numPlayers)
                }
            };
        case 'SET_MOST_COVERAGE_FIRSTNAME':
            return { ...state, mostCoverageFirst: getMostCoverage(state.allFreqsFirst) };
        case 'SET_MOST_COVERAGE_LASTNAME':
            return { ...state, mostCoverageLast: getMostCoverage(state.allFreqsLast) };
        case 'SET_SAMPLE_PLAYER_AVG':
            result = getSampleName(state.players, state.avgFirstName, state.avgLastName);
            x = Math.floor(Math.random() * Math.floor(result.length));

            return {
                ...state,
                playerAvg: {
                    firstName: result[x].firstName,
                    lastName: result[x].lastName,
                    id: result[x].id,
                    position: result[x].position,
                    jerseyNumber: result[x].jerseyNumber,
                    team: result[x].team,
                    headshot: result[x].headshot,
                }
            };
        case 'SET_SAMPLE_PLAYER_MOST_FREQ':
            result = getSampleName(state.players, state.mostFreqFirstName, state.mostFreqLastName);
            x = Math.floor(Math.random() * Math.floor(result.length));

            return {
                ...state,
                playerMostFreq: {
                    firstName: result[x].firstName,
                    lastName: result[x].lastName,
                    id: result[x].id,
                    position: result[x].position,
                    jerseyNumber: result[x].jerseyNumber,
                    team: result[x].team,
                    headshot: result[x].headshot,
                }
            };
        case 'SET_SAMPLE_PLAYER_MOST_COVERAGE':
            result = getSampleName(state.players, state.mostCoverageFirst, state.mostCoverageLast);
            x = Math.floor(Math.random() * Math.floor(result.length));

            return {
                ...state,
                playerMostCoverage: {
                    firstName: result[x].firstName,
                    lastName: result[x].lastName,
                    id: result[x].id,
                    position: result[x].position,
                    jerseyNumber: result[x].jerseyNumber,
                    team: result[x].team,
                    headshot: result[x].headshot,
                }
            };
        default:
            return state;
    }
}

const StateProvider = ({ children }) => {
    const [newState, dispatch] = useReducer(reducer, initialState);

    async function loadRosters(endPoints, teams, authKey) {
        axios.defaults.headers.common['Authorization'] = authKey;

        const requests = teams.map(teamId => {
            try {
                return axios.get(endPoints.roster + teamId).then(r => r.data);
            } catch (e) {
                //
            }
            return [];
        });

        const results = (await Promise.all(requests));
        dispatch({ type: 'SET_PLAYERS', value: results.map(r => r.teamPlayers).flat() });
        dispatch({ type: 'SET_TEAM_LOGOS', value: results.map(r => r.team) });
        dispatch({ type: 'SET_COUNTS_FIRSTNAME' });
        dispatch({ type: 'SET_COUNTS_LASTNAME' });
        dispatch({ type: 'SET_AVG_FIRSTNAME' });
        dispatch({ type: 'SET_AVG_LASTNAME' });
        dispatch({ type: 'SET_MOST_FREQUENT_FIRSTNAME' });
        dispatch({ type: 'SET_MOST_FREQUENT_LASTNAME' });
        dispatch({ type: 'SET_ALL_FREQS_FIRSTNAME' });
        dispatch({ type: 'SET_ALL_FREQS_LASTNAME' });
        dispatch({ type: 'SET_MOST_COVERAGE_FIRSTNAME' });
        dispatch({ type: 'SET_MOST_COVERAGE_LASTNAME' });
        dispatch({ type: 'SET_SAMPLE_PLAYER_AVG' });
        dispatch({ type: 'SET_SAMPLE_PLAYER_MOST_FREQ' });
        dispatch({ type: 'SET_SAMPLE_PLAYER_MOST_COVERAGE' });

        return results;
    }

    useEffect(() => {
        const dateString = () => {
            let date = new Date();
            let dd = date.getDate();
            dd = dd < 10 ? '0' + dd : dd;
            let mm = date.getMonth() + 1;
            mm = mm < 10 ? '0' + mm : mm;
            let yyyy = date.getFullYear();

            return mm.toString() + dd.toString() + yyyy.toString();
        }

        // const currentYear = () => {
        //     let date = new Date();
        //     return date.getFullYear();
        // }


        const ngs_username = process.env.REACT_APP_NGS_USERNAME;
        const ngs_password = process.env.REACT_APP_NGS_PASSWORD;
        const ngs_access_token = process.env.REACT_APP_NGS_ACCESS_TOKEN;
        const ngs_secret_key = process.env.REACT_APP_NGS_SECRET_KEY;

        const string_to_sign = ngs_username + ngs_password + ngs_access_token + dateString();
        const digest = crypto.createHmac('sha1', ngs_secret_key).update(string_to_sign).digest('base64');
        const authKey = 'NGS ' + ngs_access_token + ':' + digest;

        const url = 'https://api.ngs.nfl.com';
        // const season = '&season=' + currentYear();
        // const season = '&season=' + (currentYear() - 1);  // FOR DEV IN OFF SEASON - CURRENT SEASON HAS NO DATA YET
        const endPoints = {
            schedule: url + '/league/schedule/current',
            roster: url + '/league/roster/current?teamId=',
            team: url + '/league/teams'
        }

        const teams = [
            '3800', '0200', '0325', '0610', '0750', '0810', '0920', '1050',
            '1200', '1400', '1540', '1800', '2120', '2200', '2250', '2310',
            '2700', '3000', '3200', '3300', '3410', '3430', '2520', '3700',
            '3900', '4400', '4600', '4500', '2510', '4900', '2100', '5110'
        ];

        loadRosters(endPoints, teams, authKey);
    }, []);

    return <Provider value={{ state: newState, dispatch: dispatch }}>{children}</Provider>;
}


export { store, StateProvider }