import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  CardHeader,
  Grid,
} from '@material-ui/core';

// import { store } from './Store.js';
import './App.css';
import Average from './Average.js';
import MostFrequent from './MostFrequent.js';
import MostCoverage from './MostCoverage.js';
import FrequenciesFirst from './FrequenciesFirst.js';
import FrequenciesLast from './FrequenciesLast.js';


const styles = theme => ({
  root: {
    margin: 4,
    display: "flex",
    flexDirection: "column"
  },
});


const App = (props) => {
  // const globalState = useContext(store);
  // const { state } = globalState;
  // state.teamData.length ? console.log('teamData: ', state.teamData[0].season) : console.log('waiting')

  return (
    <div className={props.classes.root}>
      <CardHeader
        title="Player Name Study"
        subheader="First & Last Name Character Counts For Team Rosters"
      />
      <div className={props.classes.appBarOffset} />
      <main>
        <div className={props.classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* <Card className={props.classes.playerCard}>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  noWrap
                  className={props.classes.title}
                >
                  Active Players {state.players.length}
                </Typography>
              </Card> */}
            </Grid>
            <Grid item xs={12} md={4}>
              <Average />
            </Grid>
            <Grid item xs={12} md={4}>
              <MostFrequent />
            </Grid>
            <Grid item xs={12} md={4}>
              <MostCoverage />
            </Grid>
            <Grid item xs={12}>
              <FrequenciesFirst />
            </Grid>
            <Grid item xs={12}>
              <FrequenciesLast />
            </Grid>
          </Grid>
        </div>
      </main>
    </div>
  );
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(App);