import React from 'react';
import PropTypes from 'prop-types';
import {
    withStyles,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';

import CollectionsIcon from '@material-ui/icons/Collections';
import CategoryIcon from '@material-ui/icons/Category';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
// import AmpStoriesIcon from '@material-ui/icons/AmpStories';

const styles = theme => ({
    icon: {
      marginRight: 8,
    }
  });

const MenuList = (props) => {
    const { listItems, handleSwitchMenuItem } = props;

    const iconNode = (menuItem) => {
        if(menuItem === 'Interstitial Batching'){
            return <CollectionsIcon className={props.classes.icon} color={"primary"}/>
        }
        else if(menuItem === 'Team Asset Import'){
            return <CategoryIcon className={props.classes.icon} color={"primary"}/>
        }
        else{
            return <BubbleChartIcon className={props.classes.icon} color={"primary"}/>
        }
    }

    
    return (
        <List>
            {listItems.map((text, index) => (
                <ListItem button key={text}>
                    {iconNode(text)}
                    <ListItemText
                    primary={text}
                    onClick={() => handleSwitchMenuItem(text)} />
                </ListItem>
            ))}
        </List>
    );
}

MenuList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuList);
// export default MenuList;